import React from 'react';
import Text from "../UI/Text";
import Widget from "../UI/Widget";
import List from "../UI/List";
import LI from "../UI/List/Item";
import {Link} from "react-router-dom";
import Logo from '../../assets/img/logo-dark.png'

function Footer() {
    return (
        <footer className="footer-area sp-bottom">
            <div className="container">
                <div className="row mtn-40">
                    <div className="col-lg-4 order-4 order-lg-0">
                        <div className="widget-item">
                            <div className="about-widget">
                                {/*<Link to={`${process.env.PUBLIC_URL + "/"}`}>*/}
                                {/*    <img src={Logo} alt="Logo"/>*/}
                                {/*</Link>*/}

                                {/*<Text>*/}
                                {/*    During the summer my wife and I got to go on an amazing road trip in Vancouver.*/}
                                {/*</Text>*/}

                                <Text classes="copyright-txt">
                                    &copy; {new Date().getFullYear()} Strategic Wellness Advocates LLC. All Rights Reserved.
                                </Text>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 col-lg-2 ml-auto">
                        <Widget title="Information">
                            <List classes="widget-list">
                                <LI><Link to={`${process.env.PUBLIC_URL + "/about"}`}>Our company</Link></LI>
                                <LI><Link to={`${process.env.PUBLIC_URL + "/contact"}`}>Contact us</Link></LI>
                                {/*<LI><Link to={`${process.env.PUBLIC_URL + "/services"}`}>Our services</Link></LI>*/}
                                {/*<LI><Link to={`${process.env.PUBLIC_URL + "/"}`}>Careers</Link></LI>*/}
                            </List>
                        </Widget>
                    </div>

                    <div className="col-md-4 col-lg-2 ml-auto">
                        <Widget title="Social Links">
                            <List classes="widget-list">
                                <LI><Link to={{pathname: "https://www.facebook.com/profile.php?id=100087848000142"}} target={'_blank'}>Facebook</Link></LI>
                                <LI><Link to={{pathname: "https://www.instagram.com/"}} target={'_blank'}>Instagram</Link></LI>
                            </List>
                        </Widget>
                    </div>

                    <div className="col-md-4 col-lg-3">
                        <Widget title="Contact Us">
                            <address>
                                2901 DRUID PARK DRIVE
                                <br/>Suite 303 A, B &amp; C<br/>
                                Baltimore, MD 21215, USA
                                <br/><a href="https://goo.gl/maps/yHGJdvzuA7qPMono8" className="tel-no" target="_blank"><span className="timeline-icon">
                                    {/*<i className={`fa fa-map`}/>*/}
                                    see on maps</span></a><br/>
                                TEL: 443-869-3660, 202-455-2220
                                FAX: 202-919-3040
                            </address>
                        </Widget>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
import React, {useState} from 'react';
import FormInput from "../../components/UI/Input";

const From = ({sendMessage}) => {
    const [message, setMessage] = useState({})
    const [sent, setSent] = useState(false)
    const onSubmit = (e) => {
        e.preventDefault();
        sendMessage(message, () => setSent(true));
    }
    if (sent) {
        return <div>Message sent successfully</div>
    }

    return (
        <div className="contact-form-wrap">
            <form id="contact-form" onSubmit={onSubmit} method="post">
                <div className="row">
                    <div className="col-md-6">
                        <FormInput
                            tag={'input'}
                            data={message}
                            setData={(data)=> setMessage(data)}
                            type={'text'}
                            name={'first_name'}
                            placeholder={'First Name *'}
                            required
                        />
                    </div>

                    <div className="col-md-6">
                        <FormInput
                            tag={'input'}
                            data={message}
                            setData={(data)=> setMessage(data)}
                            type={'text'}
                            name={'last_name'}
                            placeholder={'Last Name *'}
                            required
                        />
                    </div>

                    <div className="col-md-6">
                        <FormInput
                            tag={'input'}
                            type={'email'}
                            data={message}
                            setData={(data)=> setMessage(data)}
                            name={'email_address'}
                            placeholder={'Email address *'}
                            required
                        />
                    </div>

                    <div className="col-md-6">
                        <FormInput
                            tag={'input'}
                            type={'text'}
                            data={message}
                            setData={(data)=> setMessage(data)}
                            name={'phone_no'}
                            placeholder={'Phone No'}
                            required
                        />
                    </div>

                    <div className="col-12">
                        <FormInput
                            tag={'textarea'}
                            data={message}
                            setData={(data)=> setMessage(data)}
                            name={'message'}
                            placeholder={'Write Your Message *'}
                        />

                        <FormInput
                            formId="contact-form"
                            tag={'button'}
                            classes={'btn-outline'}
                        />

                        <div className="form-message"/>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default From;
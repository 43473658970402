import {Link} from "react-router-dom";
import React, {Component} from 'react';
import logo from '../../assets/img/logo_embossed.jpg'

class Logo extends Component {
    render() {
        return (
            <div className="logo-area">
                <Link to="/">
                    {/*550×318*/}
                    <img src={logo} alt="StrategicWellness-Logo" />
                </Link>
            </div>
        );
    }
}

export default Logo;
import { collection, query, where, getDocs, getFirestore, doc, setDoc, addDoc } from "firebase/firestore";
import {initializeApp, getApps, getApp} from "firebase/app";
import firebaseConfig from "../fb-config.json";

export const sendMessage = async (message, onSuccess, onFailure) => {
    const app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApp();
    const db = getFirestore(app);
    addDoc(collection(db, "messages"), {...message, source: "web", created: new Date()})
        .then((d) => {
            if (onSuccess) {
                onSuccess("Message sent Successfully");
            }
        })
        .catch((error) => {
            console.log(error.message);
            // ..
            if (onFailure) {
                onFailure(error.message);
            }
        })
}